import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useArticles } from '../articles';

export default function Articles() {
  const navigate = useNavigate();
  const articles = useArticles();

  return (
    <Fragment>
      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>title</th>
            <th>description</th>
          </tr>
        </thead>

        <tbody>
          {articles.map((article) => {
            const { id, title, description } = article;

            return (
              <tr key={id}>
                <td>
                  <Link to={`/articles/${id}`}>{id}</Link>
                </td>
                <td>{title}</td>
                <td>{description}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <button onClick={() => navigate('/articles/new')}>作成する</button>
    </Fragment>
  );
}
