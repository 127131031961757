import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import client from './client';

export const initialArticle = {
  description: '',
  title: '',
};

export function useArticle(id) {
  const navigate = useNavigate();
  const [article, setArticle] = useInitialArticle();

  useEffect(() => {
    if (id === undefined) return;

    const fetchArticle = async (id) => {
      try {
        const { data } = await client.get(`/${id}`);
        setArticle(data);
      } catch (error) {
        if (error.response.status) {
          navigate('/404.html');
        }
      }
    };

    fetchArticle(id);
  }, [id, navigate, setArticle]);

  return [article, setArticle];
}

export function useArticles() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      const { data } = await client.get();

      setArticles(data);
    };

    fetchArticles();
  }, []);

  return articles;
}

export function useCreateArticle() {
  const navigate = useNavigate();

  const createArticle = async (article, setArticle) => {
    const { status } = await client.post('', article);

    if (status === 201) {
      setArticle(initialArticle);
      navigate('/');
    }
  };

  return createArticle;
}

export function useDeleteArticle() {
  const navigate = useNavigate();

  const deleteArticle = async (id) => {
    const { status } = await client.delete(`/${id}`);

    if (status === 200) navigate('/');
  };

  return deleteArticle;
}

export function useInitialArticle() {
  return useState(initialArticle);
}

export function useUpdateArticle() {
  const navigate = useNavigate();

  const updateArticle = async (id, article, setArticle) => {
    const { status } = await client.put(`/${id}`, article);

    if (status === 200) {
      setArticle(initialArticle);
      navigate('/');
    }
  };

  return updateArticle;
}
