import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Article from './Article';
import Articles from './Articles';
import NewArticle from './NewArticle';
import NotFound from './NotFound';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Articles />} />
        <Route path="articles" element={<Articles />} />

        <Route path="articles">
          <Route path="new" element={<NewArticle />} />
          <Route path=":id" element={<Article />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
