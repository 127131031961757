import { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useArticle, useDeleteArticle, useUpdateArticle } from '../articles';

export default function Article() {
  const navigate = useNavigate();
  const { id } = useParams();
  const deleteArticle = useDeleteArticle();
  const updateArticle = useUpdateArticle();
  const [article, setArticle] = useArticle(id);

  if (Object.keys(article).length === 0) return <></>;

  return (
    <Fragment>
      <div>id: {id}</div>
      <div>
        <label>
          Title:
          <input
            type="text"
            value={article.title}
            onChange={(event) =>
              setArticle({ ...article, title: event.target.value })
            }
          />
        </label>
      </div>

      <div>
        <label>
          Description:
          <input
            type="text"
            value={article.description}
            onChange={(event) =>
              setArticle({ ...article, description: event.target.value })
            }
          />
        </label>
      </div>

      <div>
        <button onClick={() => updateArticle(id, article, setArticle)}>
          更新する
        </button>
        <button onClick={() => deleteArticle(id)}>削除する</button>
        <button onClick={() => navigate('/')}>戻る</button>
      </div>
    </Fragment>
  );
}
