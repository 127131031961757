import { useNavigate } from 'react-router-dom';

import { useCreateArticle, useInitialArticle } from '../articles';

export default function NewArticle() {
  const navigate = useNavigate();
  const [article, setArticle] = useInitialArticle();
  const createArticle = useCreateArticle();

  return (
    <>
      <div>
        <label>
          Title:
          <input
            onChange={(event) =>
              setArticle({ ...article, title: event.target.value })
            }
            type="text"
            value={article.title}
          />
        </label>
      </div>

      <div>
        <label>
          Description:
          <input
            onChange={(event) =>
              setArticle({ ...article, description: event.target.value })
            }
            type="text"
            value={article.description}
          />
        </label>
      </div>

      <div>
        <button
          onClick={() => createArticle(article, setArticle)}
          type="button"
        >
          作成する
        </button>
        <button onClick={() => navigate('/')}>戻る</button>
      </div>
    </>
  );
}
